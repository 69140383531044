import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const NotFound404Animation = () => {
    const containerRef = useRef(null);
    const animationRef = useRef(null);
  
    useEffect(() => {
      animationRef.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg', // Use 'svg' or 'canvas' based on your preference
        loop: true,
        autoplay: true,
        animationData: require('../../assets/lonely-404.json'), // Import your animation JSON file
      });
  
      return () => {
        animationRef.current.destroy();
      };
    }, []);
  
    return <span ref={containerRef} />;
  };

  export default NotFound404Animation;
  