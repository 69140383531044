//Function for finding rate of interest for the loan using tenure, emi, intial loan amount, etc.

export function rate(nper, pmt, pv, fv, type, guess) {

    // Sets default values for missing parameters
    fv = typeof fv !== 'undefined' ? fv : 0;
    type = typeof type !== 'undefined' ? type : 0;
    guess = typeof guess !== 'undefined' ? guess : 0.1;

    // Sets the limits for possible guesses to any
    // number between 0% and 100%
    var lowLimit = 0;
    var highLimit = 1;

    // Defines a tolerance of up to +/- 0.00005% of pmt, to accept
    // the solution as valid.
    var tolerance = Math.abs(0.00000005 * pmt);

    // Tries at most 40 times to find a solution within the tolerance.
    for (var i = 0; i < 40; i++) {
        // Resets the balance to the original pv.
        var balance = pv;

        // Calculates the balance at the end of the loan, based
        // on loan conditions.
        for (var j = 0; j < nper; j++) {
            if (type === 0) {
                // Interests applied before payment
                balance = balance * (1 + guess) + pmt;
            } else {
                // Payments applied before insterests
                balance = (balance + pmt) * (1 + guess);
            }
        }

        // Returns the guess if balance is within tolerance.  If not, adjusts
        // the limits and starts with a new guess.
        if (Math.abs(balance + fv) < tolerance) {
            return (guess * 100) * 12;

        } else if (balance + fv > 0) {
            //console.log("stuck in else if")

            // Sets a new highLimit knowing that
            // the current guess was too big.
            highLimit = guess;
        } else {
            //console.log("stuck in else")
            // Sets a new lowLimit knowing that
            // the current guess was too small.
            lowLimit = guess;
        }

        // Calculates the new guess.
        guess = (highLimit + lowLimit) / 2;
    }
}

export function getEMIAmount(rate, tenureMonths, loanAmount) {
    const monthlyRate = rate / 12 / 100;
    const monthlyPayment = (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -tenureMonths));

    return monthlyPayment;
}

export const gst = 0.18;

export function calculateInterestRate(emi, loanAmount, tenure) {
    // Convert annual interest rate to monthly interest rate
    function monthlyInterestRate(rate) {
        return rate / 12 / 100;
    }

    // PMT formula to calculate EMI
    function calculateEMI(rate) {
        const r = monthlyInterestRate(rate);
        const n = tenure;
        return loanAmount * r * Math.pow(1 + r, n) / (Math.pow(1 + r, n) - 1);
    }

    let lowerRate = 0;
    let upperRate = 100; // Assume an upper bound for interest rate
    let tolerance = 0.01; // Tolerance for precision (adjust as needed)

    while (upperRate - lowerRate > tolerance) {
        const midRate = (lowerRate + upperRate) / 2;
        const calculatedEMI = calculateEMI(midRate);
        if (calculatedEMI > emi) {
            upperRate = midRate;
        } else {
            lowerRate = midRate;
        }
    }

    return lowerRate;
}



export function calculateIRR(values, guess) {
    // values: an array of cash flows (positive or negative)
    // guess: an initial guess for the IRR

    var tolerance = 0.00001;
    var maxIterations = 1000;
    var iteration = 0;
    var result = guess;

    do {
        var NPV = 0;
        var dNPV = 0;

        for (var i = 0; i < values.length; i++) {
            NPV += values[i] / Math.pow(1 + result, i);
            dNPV -= values[i] * i / Math.pow(1 + result, i + 1);
        }

        var newResult = result - (NPV / dNPV);
        var error = Math.abs(newResult - result);

        result = newResult;
        iteration++;

    } while (error > tolerance && iteration < maxIterations);

    if (iteration === maxIterations) {
        throw new Error("IRR calculation failed: maximum iterations reached.");
    }

    return result;
}