export const Container = ({ children }) => {
    return <div className="container py-3">
        {children}
    </div>
}

export const Row = ({ children }) => {
    return <div className="row"></div>
}

export function ContentSection({ children }) {
    return (
        <div className="row">
            <div className="col-12 col-md-1"></div>
            <div className="col-12 col-md">
                {children}
            </div>
            <div className="col-12 col-md-1"></div>
        </div>
    )
}

export const TitleAndDescription = ({ title, description }) => {
    return (
        <>
            <h1 className="display-5 my-3 text-dark-green">
                {title}
            </h1>
            <p className="text-left pb-3">
                {description}
            </p>
        </>
    )
}