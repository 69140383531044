import styled from "styled-components";
import { PrimaryButton } from "./PrimaryButton";

export const NoBorderButton = styled(PrimaryButton)`
    background-color: transparent;
    color: white;
    border: 0;

    &:hover {
        color: white;
        background-color: transparent;
    }

    &:disabled{
        color: grey;
        transform: none;
        cursor: auto;
    }
`