export const setAmazonAffOpenedCookie = (value = true) => {
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 10);
    document.cookie = `amazonAffLinkOpened=${value}; expires=${expires.toUTCString()}; path=/`;
} 

export const setFlipkartAffOpenedCookie = (value = true) => {
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 10);
    document.cookie = `flipkartAffLinkOpened=${value}; expires=${expires.toUTCString()}; path=/`;
}