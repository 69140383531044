import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { page_titles } from "./PageTitles";

function ContactUS() {
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <>
            <Helmet>
                <title>{page_titles["contact-us"]}</title>
            </Helmet>            

            <div className="bg-layered-peaks">
                <div className="container pt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link className="breadcrumbs-link" to="/"><i className="fa fa-home fa-xs"></i></Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ol>
                    </nav>
                </div>

                <div className="container mx-auto">

                    <div className="row">
                        <div className='col-1 col-lg-1'></div>
                        <div className='col-10  col-lg-6 my-5'>
                            <div className="row">
                                <p className="text-justified">If you see any glitch in the matrix or have a feedback or suggestion for any of our current or upcoming tools, do let us know.</p>
                            </div>

                            <div className="row my-3">
                                <p>We are also looking for talented people who want to join us in our journey, if that excites you, shoot a mail to&nbsp;<a href="mailto:hello@pFinTools.com" className="link-page">hello@pFinTools.com</a>&nbsp;or drop a word using the form below.</p>
                            </div>


                            <div className="row my-5">
                                <h2 className="text-center text-dark-green"><strong>Write to us</strong><br /></h2>
                                <h6 className="text-center text-pastel-green">and we will get back to you</h6>
                            </div>



                            <form className="contact-form my-5" action="https://formsubmit.co/hello@pFinTools.com" method="POST">
                                <input type="text" id="name" className="form-control form-control-sm" name="name" placeholder="Name" required />
                                <input type="email" name="email" className="form-control form-control-sm" placeholder="Email Address" />
                                <textarea id="message" name="message" className="form-control form-control-sm" placeholder="Message" required />
                                <input type="hidden" name="_subject" value="pFinTools | Contact us Submission" />
                                <input type="hidden" name="_autoresponse" value="Thank you for your message!" />
                                <input type="hidden" name="_template" value="table" />
                                <input type="submit" value="Send"></input>
                                <input type="hidden" name="_captcha" value="false" />
                                <input type="hidden" name="_next" value="https://pfintools.com/thank-you" />
                            </form>

                            <div className="row my-3">
                                <div className="col">
                                    <h5 className="text-center">You can also connect with us on:</h5>
                                    <p className="brand-icons text-center my-3" id="contact-us-brand-icons">
                                        <span>
                                            <a className="link-page" target={"_blank"} rel="noreferrer" href="https://twitter.com/pfintools"><i className="fa-brands fa-twitter"></i></a>
                                        </span>
                                        <span>
                                            <a className="link-page" target={"_blank"} rel="noreferrer" href="https://www.linkedin.com/company/pFinTools"><i className="fa-brands fa-linkedin"></i></a>
                                        </span>
                                        <span>
                                            <a className="link-page" target={"_blank"} rel="noreferrer" href="https://www.reddit.com/r/pFinTools/"><i className="fa-brands fa-reddit"></i></a>
                                        </span>
                                        <span>
                                            <a className="link-page" target={"_blank"} rel="noreferrer" href="https://www.instagram.com/pfintools/"><i className="fa-brands fa-instagram"></i></a>
                                        </span>
                                        <span>
                                            <a className="link-page" target={"_blank"} rel="noreferrer" href="https://discord.gg/WaphPUVMpb"><i className="fa-brands fa-discord"></i></a>
                                        </span>
                                        
                                    </p>

                                </div>

                            </div>
                        </div>
                        <div className='col-1 col-lg-5'>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default ContactUS;