import { useEffect, useState } from "react";
import styled from "styled-components"

const EXTENSION_LINK = "https://chrome.google.com/webstore/detail/credit-card-emino-cost-em/jnapejkpdpoacdgankigcbaekfgpkfcb";

export default function CommonTopBar() {
    const [dismissed, setDismissed] = useState(false);

    useEffect(() => {
        // Check if the bar has been dismissed previously
        const isDismissed = document.cookie.split('; ').find(row => row.startsWith('topBarDismissed='));
        if (isDismissed) {
            setDismissed(isDismissed.split('=')[1] === 'true');
        }
        else {
            setDismissed(false);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleDismiss = () => {
        setDismissed(true);
        const expires = new Date();
        expires.setHours(23, 59, 59, 999); // Set the expiry time to the end of the day
        document.cookie = `topBarDismissed=true; expires=${expires.toUTCString()}; path=/`;
    };

    if (dismissed) {
        return null;
    }

    return (
        <Root>
            <p>
                Get the best price and find all hidden charges on Amazon right from the product page!
            </p>
            <ActionButton target="_blank" href={EXTENSION_LINK}>
                Add to Browser
            </ActionButton>
            <DismissButton onClick={handleDismiss}><i className="fa fa-close"></i></DismissButton>
        </Root>
    )
}

const Root = styled.div`
    width: 100%;
    background-color: var(--light-blue);
    background-color: white;
    color: black;
    min-height: 45px;
    display: flex;
    align-items: center;
    z-index: 1000;
    position: sticky;
    top: 0;
    text-align: center;
    justify-content: center;
    @media (max-width: 860px) {
        gap: 10px;
    }
`

const DismissButton = styled.button`
    position: absolute;
    right: 25px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    line-height: 1;
    @media (max-width: 860px) {
        position: static;
        margin-right: 10px;
    }
`
export const ActionButton = styled.a`
    background-color: black;
    color: white;
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    padding: 4px 8px;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: white;
    }

    @media (max-width: 860px) {
        margin: 10px 10px;
    }
`