const checkAffCookie = (cookieName) => {
    const affLinkOpenedCookie = document.cookie.split('; ').find(row => row.startsWith(`${cookieName}=`));
    let affLinkOpened = false;

    if (affLinkOpenedCookie) {
        affLinkOpened = affLinkOpenedCookie.split('=')[1] === 'true';
    }

    return affLinkOpened;
}

export const checkAmazonAffCookie = () => {
    return checkAffCookie('amazonAffLinkOpened');
}

export const checkFlipkartAffCookie = () => {
    return checkAffCookie('flipkartAffLinkOpened');
}
