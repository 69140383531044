import styled from "styled-components";
import { PrimaryButton } from "./PrimaryButton";

export const CancelButton = styled(PrimaryButton)`
    background-color: transparent;
    color: white;
    border: 1px solid white;

    &:hover {
        transform: scale(0.9);
        color: white;
        background-color: transparent;
        border: 1px solid white;
    }
`