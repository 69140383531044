function Spinner() {
    return (
        <div className="height-fixed">
            <div className="newtons-cradle mx-auto">
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
            </div>
        </div>

    )
}

export default Spinner;