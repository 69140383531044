import styled from "styled-components"
import investing from "../../../assets/investing.svg";

export function RefferalModal() {
    return (
        <div className="modal fade" id="referralModal" tabindex="-1" aria-labelledby="referralModalLabel" aria-hidden="true">
            <div className="modal-dialog ">
                <div className="modal-content mt-5 mx-auto text-black">
                    <div className="modal-header">
                        <h5 className="modal-title" id="referralModalLabel">Start Investing In</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body row">
                        <div className="col child-center">
                            <div className="row d-flex flex-column">
                                <CustomModalButton className="text-wrap link-page pb-2" href="https://zerodha.com/?c=PJ0794" target={"_blank"} rel="noreferrer">Stocks</CustomModalButton>
                                <CustomModalButton className="text-wrap link-page pt-2" href="https://kuvera.in/s/eml?referral%3D382H0" target={"_blank"} rel="noreferrer">Mutual Funds</CustomModalButton>
                            </div>
                        </div>
                        <div className="col">
                            <img src={investing} className="w-100" alt="Investor" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const CustomModalButton = styled.a`
    color: var(--blue);
    &:hover {
        color: var(--blue);
    }
`