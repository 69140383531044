import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from './PrimaryButton';
import { NoBorderButton } from './NoBorderButton';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
`;

const ModalContent = styled.p`
  margin-bottom: 20px;
  color: black;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ConfirmButton = styled(PrimaryButton)`
    color: black;
`;

const CancelButton = styled(NoBorderButton)`
    color: black;

    &:hover{
        color: black;
    }
`;

const ConfirmModal = ({ message, onConfirm, onCancel }) => {
    return (
        <ModalOverlay>
            <ModalContainer>
                <ModalContent>{message}</ModalContent>
                <ButtonContainer>
                    <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
                    <CancelButton onClick={onCancel}>Cancel</CancelButton>
                </ButtonContainer>
            </ModalContainer>
        </ModalOverlay>
    );
};

export default ConfirmModal;
