export function getSlugFromString(str) {
    // Convert the string to lowercase
    str = str.toLowerCase();

    // Replace special characters with a space
    str = str.replace(/[^a-z0-9\s-]/g, ' ');

    // Replace multiple spaces or hyphens with a single space
    str = str.replace(/[\s-]+/g, ' ');

    // Trim spaces at the start and end of the string
    str = str.trim();

    // Replace spaces with hyphens
    str = str.replace(/\s+/g, '-');

    return str;
}