import axios from "axios";
import { getAppConfig } from "../config/appConfig";

export async function postNewUser(userData) {
    const appConfig = getAppConfig();
    const token = localStorage.getItem("token");
    // console.log("posting new userData", userData);
    await axios.post(appConfig.user, {
        name: userData.name,
        email: userData.email,
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}