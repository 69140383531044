import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import Tabs from "./Tabs";
import { Helmet } from "react-helmet";
import { page_titles } from "../PageTitles";
import Comments from "../comments/Comments";
import { Banner } from "../banners/BannerWithButtons";

function HistoricalDividendData() {

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    var [table_order, setOrder] = useState("ASC");
    const [stockData, setStockData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [formSubmitted, setformSubmitted] = useState(false);
    const [searchCompanyTerm, setsearchCompanyTerm] = useState("");
    const [searchSymbolTerm, setsearchSymbolTerm] = useState("");
    const [minDivAmt, setMinDivAmt] = useState(0);
    const [maxDivAmt, setMaxDivAmt] = useState(0);

    const today_date = new Date();
    const six_month_before = new Date(new Date().setMonth(today_date.getMonth() - 6));

    const [startDate, setStartDate] = useState(six_month_before);
    const [endDate, setEndDate] = useState(today_date);


    var start_input_string = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];

    var end_input_string = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];


    const delay = ms => new Promise(res => setTimeout(res, ms));

    const makeMyNewData = (data) => {

        const mappedData = data?.map((item) => {

            const newObject = {
                short_name: item[0],
                long_name: item[1],
                purpose: item[2],
                ex_date: item[3],
                div_amount: item[4],
            }
            return newObject;

        });
        return mappedData;
    }


    const getStockDetails = async () => {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setSpinner(true);

        const APIKey = "AIzaSyD33D6uqUzZ3xMRkg8gBdjUmsIhRfW42vE";
        const sheetID = "16tgBOxryRpN2F1fA_S-HeljzugTzZIc48N2ZqBXRDKA";
        const range = "A:E";

        const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values/${range}?key=${APIKey}`;

        try {
            const stock_response = await fetch(url)
                .then(response => response.json())
                .then(data => data.values || [])  // If data.values doesn't exist, assign an empty array
                .catch(error => {
                    console.error(error);
                    return [];  // Return empty array on fetch error
                });

            if (stock_response.length > 0) {
                const mappedItems = makeMyNewData(stock_response);
                handleMaxDivAmt(mappedItems);
                await delay(1000);
                setSpinner(false);
                return mappedItems;
            } else {
                setSpinner(false);
                return [];
            }
        }
        catch (error) {
            throw Error("There was some error in fetching the data you requested");
        }

    }

    const handleMaxDivAmt = (mappedItems) => {

        const newMaxDivAmt = mappedItems.reduce((max, item) => {

            const divAmt = parseFloat(item.div_amount);
            return divAmt > max ? divAmt : max;

        }, 0);

        setMaxDivAmt(newMaxDivAmt);
    }

    useEffect(() => {

        getStockDetails()
            .then((data) => {
                setStockData(data)
                setFilteredData(data);
            })
            .catch((error) => console.log(error));

    }, []);

    useEffect(() => {

        setFilteredData(filterDataFunc(stockData));
        setformSubmitted(false);

    }, [stockData, formSubmitted]);

    useEffect(() => {

        handleMaxDivAmt(filteredData);

    }, [filteredData])


    const DisplayData = filteredData?.map((item, index) => {
        return (
            <tr className="text-light table-cell" key={index + `${item.short_name}`}>
                <td className="my-5">{item.short_name}</td>
                <td>{item.long_name}</td>
                <td>{item.purpose}</td>
                <td>{item.ex_date}</td>
                <td>{Number(item.div_amount) ? Number(item.div_amount).toFixed(2) : "TBA"}</td>
            </tr>
        );
    });

    const sorting = (col) => {
        if (table_order === "ASC") {
            const sorted = filteredData.sort((a, b) =>
                a[col].toLowerCase().localeCompare(b[col].toLowerCase())
            );
            setFilteredData(sorted);
            setOrder("DESC");
            // setSortedFlag("true");
        } else {
            const sorted = filteredData.sort((a, b) =>
                b[col].toLowerCase().localeCompare(a[col].toLowerCase())
            );
            setFilteredData(sorted);
            setOrder("ASC");
            // setSortedFlag("true");
        }
    };

    const sortingNum = (col) => {
        if (table_order === "ASC") {
            const sorted = filteredData.sort((a, b) => {
                return Number(a[col]) - Number(b[col]);
            }
            );
            setFilteredData(sorted);
            setOrder("DESC");
        } else {
            const sorted = filteredData.sort((a, b) => {
                return Number(b[col]) - Number(a[col]);
            }
            );
            setFilteredData(sorted);
            setOrder("ASC");
        }
    }

    const sortingExDate = () => {
        if (table_order === "ASC") {
            const sorted = filteredData.sort((a, b) => {
                let date1 = new Date(a["ex_date"]);
                let date2 = new Date(b["ex_date"]);
                return date1 - date2;
            }
            );
            setFilteredData(sorted);
            setOrder("DESC");
        } else {
            const sorted = filteredData.sort((a, b) => {
                let date1 = new Date(a["ex_date"]);
                let date2 = new Date(b["ex_date"]);
                return date2 - date1
            }
            );
            setFilteredData(sorted);
            setOrder("ASC");
        }
    }



    const filterDataFunc = (data) => {

        handleMaxDivAmt(data);

        const filtered_data_array = data.filter((item) => {

            // Matches Company
            const matches_company_name = item.long_name.toString().toLowerCase().includes(searchCompanyTerm.toLowerCase());

            // Matches Symbol
            const matches_symbol = item.short_name.toString().toLowerCase().includes(searchSymbolTerm.toLowerCase());

            // Matches Dividend Amount
            const matches_div_amount = item.div_amount >= parseFloat(minDivAmt) && item.div_amount <= parseFloat(maxDivAmt);

            const itemDate = new Date(item.ex_date).getTime();
            const fromDateTime = startDate && new Date(startDate).getTime();
            const toDateTime = endDate && new Date(endDate).getTime();
            const matchesExDate = (!fromDateTime || itemDate >= fromDateTime) && (!toDateTime || itemDate <= toDateTime);

            return matchesExDate && matches_company_name && matches_symbol && matches_div_amount;
        });


        return filtered_data_array;
    }



    const handleSubmit = async (e) => {

        e.preventDefault();

        setformSubmitted(true);

        const user_entered_start_date = new Date(e.target.startDate.value);
        const user_entered_end_date = new Date(e.target.endDate.value);

        //The moment we set the start and end date state variables, useEffect should be called
        //It should fetch the new data and update the stock_data state variable
        setStartDate(user_entered_start_date);
        setEndDate(user_entered_end_date);
    }

    return (
        <>
            <Helmet>
                <title>{page_titles["historical-dividend-calendar"]}</title>
            </Helmet>

            <div className="container pt-3">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link className="breadcrumbs-link" to="/"><i className="fa fa-home fa-xs"></i></Link></li>
                        <li className="breadcrumb-item" aria-current="page"><Link className="breadcrumbs-link" to="/upcoming-dividend-calendar">Dividend Calendar</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Historic Dividend Data</li>
                    </ol>
                </nav>

                <div className="row">

                    <div className="col-md-1"></div>

                    <div className="col-12 col-md-10">
                        <h1 className="display-5 my-3 text-dark-green">Historic Dividend Data</h1>
                        <p className="text-left">
                            {/* <i className="fa fa-calendar" aria-hidden="true"></i> */}
                            <br />
                            Search all dividends declared by companies listed in India filtered by date.
                        </p>
                        <div className="Tabs">
                            <Tabs tab="historic" />
                        </div>
                    </div>
                    <div className="col-md-1">
                    </div>

                </div>



                <div className="row">
                    <div className="col-md-1"></div>

                    <div className="col-12 col-md-10">
                        <div className="filters">

                            <div className="p-3 pb-1">
                                <form onSubmit={handleSubmit}>
                                    <div className="d-flex flex-wrap">

                                        <fieldset className="border-filter ">
                                            <legend className="float-none w-auto">Symbol:</legend>
                                            <input className="form-control-sm" type="text" name="Symbol-filter" onChange={(e) => setsearchSymbolTerm(e.target.value)} />
                                        </fieldset>

                                        <fieldset className="border-filter">
                                            <legend className="float-none w-auto">Company Name:</legend>
                                            <input type="text" className="form-control-sm" name="Company-filter" onChange={(e) => setsearchCompanyTerm(e.target.value)} />
                                        </fieldset>

                                        <fieldset className="border-filter  ">
                                            <legend className="float-none w-auto">From</legend>
                                            <input className="form-control-sm " ref={startDateRef} type={"date"} defaultValue={start_input_string} name="startDate"></input>
                                        </fieldset>

                                        <fieldset className="border-filter">
                                            <legend className="float-none w-auto">To</legend>
                                            <input className="form-control-sm " ref={endDateRef} type={"date"} name="endDate" defaultValue={end_input_string}></input>
                                        </fieldset>

                                        <fieldset className="border-filter ">
                                            <legend className="float-none w-auto">Min Dividend Amount:</legend>
                                            <input className="form-control-sm" type="text" value={minDivAmt} onChange={(e) => setMinDivAmt(e.target.value)} />
                                        </fieldset>

                                        <fieldset className="border-filter ">
                                            <legend className="float-none w-auto">Max Dividend Amount:</legend>
                                            <input className="form-control-sm" type="text" value={maxDivAmt} onChange={(e) => setMaxDivAmt(e.target.value)} />
                                        </fieldset>
                                    </div>

                                    <p className="text-center">
                                        <button className="btn btn-submit mx-auto mt-3" type="submit">Apply Filters</button>
                                    </p>

                                </form>
                                <br />
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>

                </div>


                <div className="row ">
                    <div className="col-md-1"></div>
                    {/* Calendar */}
                    <div className="col-12 col-md-10">
                        <div className="dividend-table w-100 my-5">
                            {spinner ?
                                <Spinner />
                                :
                                <table className="table sortable">
                                    <thead id="table-head" className="text-light table_head">
                                        <tr>
                                            <th key="symbol" >
                                                Symbol <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sorting("short_name")}></i>
                                            </th>
                                            <th key="company" >
                                                Company <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sorting("long_name")}></i>
                                            </th>
                                            <th key="purpose">
                                                Purpose <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sorting("purpose")}></i>
                                            </th>
                                            <th key="ex_date">
                                                Ex-Date <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sortingExDate()}></i>
                                            </th>
                                            <th key="div_amt">
                                                Dividend (&#x20b9;) <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sortingNum("div_amount")}></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="table-body">
                                        {DisplayData}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>

                    <div className="col-md-1"></div>
                </div>

                <div className="row">
                    <div className="col-md-1"></div>

                    <div className="col-12 col-md-10">

                        <div className="row">
                            <p className="font-weight-light">To be eligible to get the dividend you should have bought the shares atleast one business day before the ex date. You may sell the stock on ex-date or after to get the dividend.</p>
                        </div>

                        <div className="row my-4">
                            <h5>Related Links:</h5>
                            <ul className="list-group">
                                <li className="list-group-item bg-transparent">
                                    <a className="link-page" href="https://www.investopedia.com/articles/investing/091015/how-dividends-affect-stock-prices.asp#toc-how-dividends-work">
                                        How Dividends Affect Stock Prices?
                                    </a>
                                </li>
                                <li className="list-group-item bg-transparent">
                                    <a className="link-page" href="https://www.investopedia.com/terms/e/ex-date.asp">
                                        What is ex-date?
                                    </a>
                                </li>
                                <li className="list-group-item bg-transparent">
                                    <a className="link-page" href="https://cleartax.in/s/dividend-stripping-india#:~:text=To%20address%20this%2C%20section%2094,dividends%20declared%20by%20the%20company">
                                        Tax implications of dividend stripping in India
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="row">
                            <small className="mb-5 desclaimer">
                                <strong>Disclaimer:</strong><i><br />The data presented here is for informational purpose only and does not solicit investment advise. We advise you to check with certified experts before making any investment decision.</i> <br />
                            </small>
                        </div>
                        <div>
                            <Comments articleId={'historical_dividend_data'} />
                        </div>

                        {/* Amazon Banner */}
                        <div className="my-4">
                            <Banner />
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>

            <footer></footer>
        </>
    );
}

export default HistoricalDividendData;
