import React from 'react';
import './loader.css'

const Loader = ({ isLoading, children }) => {
  return (
    <div className={`spinner-container ${isLoading ? 'loading' : ''}`}>
      {isLoading ? (
        <div className="spinner-overlay">
          <div className="spinner" />
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default Loader;
