import styled from "styled-components"
import { CustomModalButton } from "./ReferralModal"
import { CC_ADS } from "../../../utils/Constants"

export function CreditCardModal() {
    return (
        <div className="modal fade" id="ccModal" tabindex="-1" aria-labelledby="ccModalLabel" aria-hidden="true">
            <div className="modal-dialog ">
                <div className="modal-content mt-5 mx-auto text-black">
                    <div className="modal-header">
                        <h5 className="modal-title" id="ccModalLabel">Get a new Credit Card</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body row mx-4">
                        <div className="col">
                            <div className="row d-flex flex-column">
                                {CC_ADS.map((ad, index) => {
                                    return (
                                        <CustomModalButton key={index} className="text-wrap link-page py-3" href={ad.link} target={"_blank"} rel="noreferrer">{ad.content}</CustomModalButton>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
