export const page_titles = {
    "home": "pFinTools | Practical Financial Tools Platform",
    "upcoming-dividend-calendar": "pFinTools | Upcoming Dividend Calendar with Dividend Yield - India's First Practical Dividends Calendar",
    "historical-dividend-calendar": "pFinTools | Historic Dividends Calendar - India's First Practical Dividends Calendar",
    "contact-us": "Contact Us - Practical Financial Tools Platform",
    "no-cost-emi": "pFinTools | Real Cost of No Cost EMI - India's First No Cost EMI Cost Calculator",
    "blogs": "pFinTools | Finance Essentials",
    "privacy-policy": "pFinTools | Privacy Policy",
    "extension-privacy-policy": "pFinTools | Extension Privacy Policy",
    "terms-and-conditions": "pFinTools | Terms and Conditions",
    "my-profile": "pFinTools | My Profile",
    "faq": "pFinTools | Frequently Asked Questions",
}