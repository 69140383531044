import axios from "axios"
import { getAppConfig } from "../../config/appConfig"
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../App";
import { Helmet } from "react-helmet";
import { page_titles } from "../PageTitles";
import { Link } from "react-router-dom";
import "./blog.css";
import NoDataFound from "./NoDataFoundSVG";
import Spinner from "../Spinner";
import { FullScreen } from "../common-components/common-styled-components/FullScreen";

const appConfig = getAppConfig();

export default function Blog() {

    const [blogs, setBlogs] = useState([]);
    const { token } = useContext(AppContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchBlogs(appConfig.root);
    }, []);

    const formatDate = (dateString) => {
        const options = { day: "numeric", month: "short", year: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
    };

    const fetchBlogs = async () => {
        try {
            const response = await axios.get(appConfig.blogAll, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setBlogs(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching blogs:', error);
            setLoading(false);
        }
    };

    if (loading) {
        return <div className="loader-wrapper"><Spinner /></div>
    }

    return <>
        <Helmet>
            <title>{page_titles["blogs"]}</title>
        </Helmet>

        <div style={{
            gap: '60px',
            display: 'grid',
        }}>
            {blogs.length === 0 && <>
                <FullScreen className="child-center pt-5">
                    <NoDataFound />
                    <h3 className="text-center">No Blogs Found</h3>
                </FullScreen>
            </>}
        </div>
        {blogs.length !== 0 &&
            <div className="container py-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link className="breadcrumbs-link" to="/">
                                <i className="fa fa-home fa-xs"></i>
                            </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Blogs
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-md-1"></div>

                    <div className="col-12 col-md-10">
                        <h1 className="display-5 my-3 text-dark-green">Blogs</h1>
                        <div className="posts">
                            {blogs.map((blog) => (
                                <div key={blog.pageId} className="post">
                                    <div className="post-header">
                                        <h3 className="title">
                                            <Link className="title-link" to={`/blogs/${blog.pageId}`}>{blog.title}</Link>
                                        </h3>
                                    </div>
                                    <div className="desciption">
                                        {`${blog.description.substring(0, 200)}...`}
                                    </div>
                                    <div className="meta">
                                        <div className="author-tiny">
                                            {blog.author}
                                            <span className="design">{blog.designation}</span>
                                        </div>
                                        <p className="date">{formatDate(blog.publishedAt)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        }

    </>
}