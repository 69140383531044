import styled from "styled-components";

export const PrimaryButton = styled.button`
    padding: 12px;
    color: black;
    border-radius: 4px;
    display: block;
    width: 35vmax;
    min-width: fit-content;
    max-width: 10vmax;
    padding-left: 1%;
    padding-right: 1%;
    cursor: pointer;
    border: 0;

    transition-duration: 0.35s;
    transition-property: transform;
    background-color: var(--pastel-green);
    font-size: 0.9rem;

    &:hover {
        transform: scale(0.9);
        color: black;
        background-color: var(--pastel-green);
    }

    &:disabled{
        color: grey;
        transform: none;
        cursor: auto;
    }
`