import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { page_titles } from "../PageTitles";

export default function ExtensionPrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>{page_titles["extension-privacy-policy"]}</title>
      </Helmet>

      <div className="container pt-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link className="breadcrumbs-link" to="/">
                <i className="fa fa-home fa-xs"></i>
              </Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link className="breadcrumbs-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Extension Privacy Policy
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10">
            <h1>Extension Privacy Policy</h1>
            <p>Last updated: October 23, 2023</p>
            <div className="privacy-policy-content">
              <p>
                The Extension Privacy Policy is supplementary to our primary
                Privacy Policy outlined {" "}
                <a href="/privacy-policy" className="link-page">here</a>.
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You.
              </p>
              <p>
                We work with affiliate networks and get paid a commission in
                certain instances when we help users save time and money
                shopping online. Please note that We do not pass any personal
                identifying information to these networks. Other websites, such
                as retailers, may have agreements with these networks that
                include sharing of information. We do not control those websites
                or those agreements, so we have placed links to each network's
                privacy policies below if you'd like to review them.
              </p>
              <p>
                <ul>
                  <li>
                    <a href="https://www.amazon.in/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ" className="link-page">
                      Amazon.in
                    </a>
                  </li>
                </ul>
              </p>
              <p>
                By installing and using our extension, you acknowledge that you have read, understood and agree to the terms mentioned above.
              </p>
              <h2>Changes to this Privacy Policy</h2>
              <p>
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let You know of any changes by updating the &quot;Last
                updated&quot; date at the top of this Privacy Policy. You are
                advised to review this Privacy Policy periodically for any
                changes. Changes to this Privacy Policy are effective when they
                are posted on this page.
              </p>
              <h2>Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, You can
                contact us:
              </p>
              <ul>
                <li>
                  <p>By email: hello@pFinTools.com</p>
                </li>
                <li>
                  <p>
                    By visiting this page on our website:{" "}
                    <a
                      href="https://pfintools.com/contact-us"
                      rel="external nofollow noopener"
                      target="_blank"
                      className="link-page"
                    >
                      pFinTools.com/contact-us
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-1"></div>
        </div>
      </div>
    </>
  );
}
