import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Full_Logo.svg";
import ThankYouAnimation from "./ThankYouAnimation";
import "./ThankYouPage.css";
import { FOOTER_YEAR } from "../../utils/Constants";

const ThankYouPage = () => {
  useEffect(() => {
    document.title = 'Thank you for your submission | pFinTools ';
  }, []);
  return (
    <div className="bg-yellow">
      <div className="thank-you bg-black">
        <div className="container mx-auto ">
          <div className="ThankYouAnimation">
            <ThankYouAnimation />
          </div>
          <h1 className="text-center text-light-blue">Thank you for your submission!</h1>
          <h5 className="text-center">
            We appreciate your message and will get back to you soon.
          </h5>

          <p className="text-center">
            <Link className="" to="/">
              <button className="btn btn-submit mx-auto m-3" type="button">
                Back to Home
              </button>
            </Link>
          </p>
        </div>
        <div className="footer container mx-auto py-5">
          <footer className="row text-center">
            <div className="row mx-auto pt-5 w-100">
              <ul className="mx-auto px-0">
                <li className="list-inline-item mx-auto">
                  <Link className="navbar-brand" to="/">
                    <img width={80} src={Logo} alt="pFinTools Logo" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <span className="text-large mx-3">© {FOOTER_YEAR}</span>
                </li>
              </ul>

              <p className="brand-icons">
                <span>
                  <a
                    className="link-page"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://twitter.com/pfintools"
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </span>
                <span>
                  <a
                    className="link-page"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/pFinTools"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </span>
                <span>
                  <a
                    className="link-page"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.reddit.com/r/pFinTools/"
                  >
                    <i className="fa-brands fa-reddit"></i>
                  </a>
                </span>
                <span>
                  <a
                    className="link-page"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.instagram.com/pfintools/"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </span>
                <span>
                  <a
                    className="link-page"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://discord.gg/WaphPUVMpb"
                  >
                    <i className="fa-brands fa-discord"></i>
                  </a>
                </span>
              </p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
