import styled from "styled-components";
import { NoBorderButton } from "../common-components/common-styled-components/NoBorderButton";

export const RepliesContainer = styled.div`
    margin-left: 50px;
`;

export const RowFlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
`;

export const ColumnFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const CommentInput = styled.input`
    width: 100%;
    padding: 12px;
    outline: none;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #fff;
    color: white;
    min-height: calc(2em + 24px);
`;

export const CommentSectionTitle = styled.p`
    font-size: 1.1rem;
    margin-bottom: 12px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    min-width: fit-content;
    gap: 12px;
    justify-content: flex-end;
    @media (max-width: 500px) {
        justify-content: center;
    }
    button {
        &:focus {
            outline: none !important;
            box-shadow: none;;
        }
    }
    padding-top: 24px;
`;

export const LikeButton = styled(NoBorderButton)(({ $liked }) => ({
    color: $liked ? 'red' : 'white',
    boxShadow: 'none',
    border: 'none',
    width: 'fit-content',
    '&:hover': {
        color: $liked ? 'red' : 'white',
    },

    '&:disabled':{
        color: "grey",
    }
}));

export const ReplyButton = styled(NoBorderButton)`
    width: fit-content;
`;

export const DeleteButton = styled(NoBorderButton)`
    width: fit-content;
`;

export const CommentActions = styled.div`
    padding-left: 12px;
`;

export const CommentDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CommentAuthor = styled.span`
    padding-left: 12px;
    font-size: smaller;
    color: grey;
`;

export const CommentBody = styled.p`
    padding: 5px 0px 0px 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
`;

export const CommentDate = styled.span`
    font-size: 10px;
`;

export const LikeCount = styled.span`
    font-size: smaller;
    color: lightgray;
    padding-left: 8px;
`