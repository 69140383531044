import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useUserAuthentication from "./useUserAuthentication";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/Constants";
import './userAuthentication.css'
import Loader from "../loader-container/Loader";

const Signup = () => {
  const { passwordVisible, showPassword, onSignupHandler, onGoogleSignInHandler, isLoading } = useUserAuthentication();
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <Loader isLoading={isLoading}>
      <main className="signup d-flex justify-content-center align-items-center p-5">
        <div className="container d-flex justify-content-center align-items-center m-5">
          <div className="signup__form d-flex flex-column d-flex justify-content-center align-items-center">
            <h2 className="signup__heading fw-bold fs-3 mb-4">
              Sign up to your account
            </h2>
            <button className="google" onClick={onGoogleSignInHandler}>
              <i>
                <FcGoogle />
              </i>
              Continue with Google
            </button>
            <div>
              <form
                className="d-flex flex-column"
                onSubmit={handleSubmit(onSignupHandler)}
              >
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    {...register("name", {
                      required: true,
                      minLength: 3,
                      maxLength: 100,
                    })}
                  />
                  <p className="error" role="alert">
                    {errors.name && (
                      <span className="mb-3 ">
                        Name must be more than 6 characters and less than 20 characters
                      </span>
                    )}
                  </p>

                  <label htmlFor="name">Name</label>
                </div>

                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern: EMAIL_REGEX,
                    })}
                  />
                  <p className="error" role="alert">
                    {errors.email && (
                      <span className="mb-3 ">
                        Please enter a valid email ID!
                      </span>
                    )}
                  </p>

                  <label htmlFor="email">Email</label>
                </div>

                <div className="form-floating">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    {...register("password", {
                      required: true,
                      pattern: PASSWORD_REGEX,
                    })}
                  />
                  <p className="error" role="alert">
                    {errors.password && (
                      <span className="mb-3 ">
                        Password should be min 6 character!
                      </span>
                    )}
                  </p>

                  <label htmlFor="password">Password</label>
                  <a>
                    {passwordVisible ? (
                      <AiFillEyeInvisible onClick={showPassword} />
                    ) : (
                      <AiFillEye onClick={showPassword} />
                    )}
                  </a>
                </div>

                <button>Sign up</button>
              </form>
              <div className="signup__terms-and-policy text-center">
                <p>
                  By signing up, you agree to our <u>Terms of Use</u> and{" "}
                  <u>Privacy Policy..</u>
                </p>
              </div>
              <div className="signup__terms-and-policy__underline"></div>

              <div className="signup__login d-flex justify-content-center">
                <p className="m-0">
                  Already have an account?
                  <Link to="/login"> Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Loader>
  );
};

export default Signup;
