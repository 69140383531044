export const getAppConfig = () => {
    return {
        root: process.env.REACT_APP_BACKEND_ROOT,
        comment: process.env.REACT_APP_BACKEND_COMMENT,
        commentLike: process.env.REACT_APP_BACKEND_COMMENT_LIKE,
        commentDelete: process.env.REACT_APP_BACKEND_COMMENT_DELETE,
        blog: process.env.REACT_APP_BACKEND_BLOG,
        blogAll: process.env.REACT_APP_BACKEND_BLOG_ALL,
        auth: process.env.REACT_APP_BACKEND_AUTH,
        username: process.env.REACT_APP_BACKEND_USERNAME,
        password: process.env.REACT_APP_BACKEND_PASSWORD,
        user: process.env.REACT_APP_BACKEND_USER,
        userByEmail: process.env.REACT_APP_BACKEND_USER_BY_EMAIL,
        genders: process.env.REACT_APP_BACKEND_USER_GENDERS,
        errorLog: process.env.REACT_APP_BACKEND_ERROR_LOG,

        dividendFetchConfig: {
            apiKey: process.env.REACT_APP_DIVIDEND_FETCH_API_KEY,
            sheetID: process.env.REACT_APP_DIVIDEND_FETCH_SHEET_ID,
            range: process.env.REACT_APP_DIVIDEND_FETCH_RANGE,
        }
    }
}