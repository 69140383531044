import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getAppConfig } from "../../config/appConfig";
import { useContext, useEffect, useState, React, useRef } from "react";
import axios from "axios";
import { AppContext } from "../../App";
import { CancelButton } from "../common-components/common-styled-components/CancelButton";
import UserAvatar from "../common-components/common-styled-components/UserAvatar";
import { PrimaryButton } from "../common-components/common-styled-components/PrimaryButton";
import 'animate.css';
import { formatDistanceToNow } from 'date-fns';
import { showToastNotification } from "../../utils/ToastNotification";
import {
    CommentSectionTitle,
    ButtonsWrapper,
    ColumnFlexContainer,
    CommentActions,
    CommentAuthor,
    CommentBody,
    CommentDate,
    CommentDetails,
    CommentInput,
    LikeButton,
    ReplyButton,
    RepliesContainer,
    RowFlexContainer,
    LikeCount,
    DeleteButton
} from "./CommentsStyledComponents";
import ConfirmModal from "../common-components/common-styled-components/ConfirmModal";
import styled from "styled-components";
import { sendErrorLog } from "../../utils/sendErrorLog";

const appConfig = getAppConfig();

export default function Comments() {
    const [comments, setComments] = useState([]);
    let { slug } = useParams();
    const { user, isUserLoggedIn } = useContext(AppContext);
    const [commentLiteral, setCommentLiteral] = useState("Comments");
    const [newComment, setNewComment] = useState("");
    const [primaryActionFocused, setPrimaryActionFocused] = useState(false);
    const [commentIdReplyingTo, setCommentIdReplyingTo] = useState(null);
    const [newReply, setNewReply] = useState("");
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const [replyActionTriggered, setReplyActionTriggered] = useState(false);
    const loginButtonRef = useRef(null);
    const location = useLocation();
    
    const getLastPartOfURL = (path) => {
        const parts = path.split('/');
        return parts[parts.length - 1];
    };

    // If slug is undefined, set it to the last part of the URL
    slug = slug || getLastPartOfURL(location.pathname);

    useEffect(() => {
        if (primaryActionFocused && loginButtonRef.current) {
            loginButtonRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

            loginButtonRef.current.classList.add("animate__animated");
            loginButtonRef.current.classList.add("animate__heartBeat");

            const animationEndHandler = () => {
                loginButtonRef.current.classList.remove("animate__animated");
                loginButtonRef.current.classList.remove("animate__heartBeat");
                setPrimaryActionFocused(false);
            };

            loginButtonRef.current.addEventListener("animationend", animationEndHandler);

            return () => {
                loginButtonRef.current.removeEventListener("animationend", animationEndHandler);
            };
        }
    }, [primaryActionFocused, newComment, newReply, loginButtonRef]);

    useEffect(() => {
        fetchComments(setComments, slug);
    }, [slug]);

    useEffect(() => {
        if (comments.length === 1) {
            setCommentLiteral("Comment");
        }
    }, [comments]);

    const handleCommentInput = (e) => {
        setNewComment(e.target.value);
        if (!isUserLoggedIn || !user.name) {
            setPrimaryActionFocused(true);
        }
    }

    function handleLikeButton(comment) {
        const token = localStorage.getItem('token');
        if (isUserLoggedIn) {
            axios.post(`${appConfig.comment}/like`, {
                id: comment.id,
                email: user.email,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(() => fetchComments(setComments, slug));
        }
        else {
            setPrimaryActionFocused(true);
        }
    }

    const handleSubmitComment = (e) => {
        if (newComment.length > 0) {
            if (user.name === null || user.name === undefined) {
                showToastNotification("Please go to the 'My Profile' page and add your name to leave a comment.", 'info', 'comments');
            }
            postCommentThenFetch(newComment, slug, user, setComments, null);
            setNewComment("");
        }
    }

    const handleSubmitReply = (e) => {
        if (newReply.length > 0) {
            postCommentThenFetch(newReply, slug, user, setComments, commentIdReplyingTo);
            setNewReply("");
        }
    }

    const handleReply = (comment) => {
        setReplyActionTriggered(true);

        setNewReply("")
        setCommentIdReplyingTo(comment.id);
        if (!isUserLoggedIn) {
            setPrimaryActionFocused(true);
        }
    }

    const handleReplyInput = (e) => {
        setNewReply(e.target.value);
        if (!isUserLoggedIn) {
            setPrimaryActionFocused(true);
        }
    }

    const addCommentSectionProps = {
        user: user,
        newComment: newComment,
        setNewComment: setNewComment,
        isUserLoggedIn: isUserLoggedIn,
        handleCommentInput: handleCommentInput,
        handleSubmitComment: handleSubmitComment,
        primaryActionFocused: primaryActionFocused,
        newReply: newReply,
        loginButtonRef: loginButtonRef,
        location: location
    }

    const handleDeleteComment = (comment) => {
        setShowConfirmDialog(true);
        setCommentToDelete(comment);
    }

    const handleCancelReply = () => {
        setReplyActionTriggered(false);
    }

    const actionButtonHandlers = {
        like: handleLikeButton,
        reply: handleReply,
        delete: handleDeleteComment,
        submitReply: handleSubmitReply,
        cancelReply: handleCancelReply,
    }

    return (
        <Root>
            {showConfirmDialog &&
                <ConfirmModal
                    message="Are you sure you want to delete this comment?"
                    onConfirm={() => {
                        deleteCommentThenFetch(commentToDelete, slug, setComments)
                        setShowConfirmDialog(false)
                    }}
                    onCancel={() => setShowConfirmDialog(false)}
                />}
            <CommentSectionTitle>{comments.length} {commentLiteral}</CommentSectionTitle>
            <AddCommentSection props={addCommentSectionProps} />
            <CommentsList
                comments={comments}
                currentUser={user}
                commentIdReplyingTo={commentIdReplyingTo}
                handleCommentInput={handleCommentInput}
                newReply={newReply}
                handleReplyInput={handleReplyInput}
                isUserLoggedIn={isUserLoggedIn}
                setNewReply={setNewReply}
                actionButtonHandlers={actionButtonHandlers}
                replyActionTriggered={replyActionTriggered}
            />
        </Root>
    )
}

const CommentsList = ({
    comments,
    currentUser,
    commentIdReplyingTo,
    handleCommentInput,
    newReply,
    handleReplyInput,
    isUserLoggedIn,
    setNewReply,
    primaryActionFocused,
    actionButtonHandlers,
    replyActionTriggered,
}) => {

    return (
        <ColumnFlexContainer>
            {comments.map((comment) => {

                let isDeleted = comment.isDeleted;

                return (
                    <ColumnFlexContainer key={comment.id}>

                        <RowFlexContainer>
                            <UserAvatar photoUrl={!isDeleted && comment.user ? comment.user.photoUrl : null} />
                            {showComment(comment, currentUser, actionButtonHandlers, isDeleted)}
                        </RowFlexContainer>


                        {commentIdReplyingTo === comment.id &&
                            isUserLoggedIn &&
                            <ReplyCommentSection
                                user={currentUser}
                                newReply={newReply}
                                handleReplyInput={handleReplyInput}
                                setNewReply={setNewReply}
                                handleSubmitReply={actionButtonHandlers.submitReply}
                                handleCancelReply={actionButtonHandlers.cancelReply}
                                replyActionTriggered={replyActionTriggered}
                            />
                        }

                        {comment.replies.length > 0 &&
                            <RepliesContainer>
                                <CommentsList
                                    comments={comment.replies}
                                    currentUser={currentUser}
                                    commentIdReplyingTo={commentIdReplyingTo}
                                    handleCommentInput={handleCommentInput}
                                    newReply={newReply}
                                    handleReplyInput={handleReplyInput}
                                    isUserLoggedIn={isUserLoggedIn}
                                    setNewReply={setNewReply}
                                    primaryActionFocused={primaryActionFocused}
                                    actionButtonHandlers={actionButtonHandlers}
                                    replyActionTriggered={replyActionTriggered}
                                />
                            </RepliesContainer>
                        }
                    </ColumnFlexContainer>
                );
            })}
        </ColumnFlexContainer>
    )
}

const ReplyCommentSection = ({ user, newReply, handleReplyInput, setNewReply, handleSubmitReply, handleCancelReply, replyActionTriggered }) => {

    const [hide, setHide] = useState(false);
    const [userTyping, setUserTyping] = useState(false);

    useEffect(() => {
        setUserTyping(newReply.length > 0)
    }, [newReply])

    useEffect(() => {
        setHide(false);
    }, [replyActionTriggered])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmitReply();
            setHide(true);
        }
    }

    useEffect(() => {
        if (hide) {
            setNewReply("");
        }
    }, [hide])

    const handleCancel = () => {
        setHide(true);
        handleCancelReply();
    }

    if (replyActionTriggered)
        return (
            <RepliesContainer>
                {!hide && <ColumnFlexContainer>
                    <RowFlexContainer>
                        <UserAvatar photoUrl={user?.photoURL} />
                        <CommentInput
                            placeholder="Reply..."
                            onChange={(e) => {
                                handleReplyInput(e)
                            }}
                            onKeyDown={(e) => handleKeyDown(e)}
                            value={newReply} />
                    </RowFlexContainer>
                    <ButtonsWrapper>
                        <>
                            <CancelButton className="btn btn-dark" onClick={(_) => handleCancel()}>Cancel</CancelButton>
                            <PrimaryButton
                                className="btn submit-button"
                                onClick={() => {
                                    handleSubmitReply()
                                    setHide(true)
                                }}
                                disabled={!userTyping}
                            >Comment</PrimaryButton>
                        </>
                    </ButtonsWrapper>
                </ColumnFlexContainer>
                }
            </RepliesContainer>
        )
}

const AddCommentSection = ({
    props: {
        user,
        newComment,
        setNewComment,
        isUserLoggedIn,
        handleCommentInput,
        handleSubmitComment,
        loginButtonRef,
        location
    }
}) => {

    const navigate = useNavigate();
    const [showActionButtons, setShowActionButtons] = useState(false);
    const [userTyping, setUserTyping] = useState(false);

    useEffect(() => {
        setUserTyping(newComment.length > 0)
    }, [newComment])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmitComment();
        }
    }

    return <>
        <RowFlexContainer >
            <UserAvatar photoUrl={user?.photoURL} />
            <CommentInput
                placeholder="Add a new comment..."
                onChange={(e) => {
                    setShowActionButtons(true);
                    handleCommentInput(e)
                }}
                onKeyDown={(e) => handleKeyDown(e)}
                value={newComment} />
        </RowFlexContainer>
        <ButtonsWrapper className="mb-3">
            {isUserLoggedIn ? <>
                {showActionButtons &&
                    <>
                        <CancelButton className="btn btn-dark" onClick={() => {
                            setShowActionButtons(false)
                            setNewComment("")
                        }}>Cancel</CancelButton>
                        <PrimaryButton disabled={!userTyping} className="btn submit-button" onClick={() => handleSubmitComment()}>Comment</PrimaryButton>
                    </>
                }
            </>
                :
                <PrimaryButton ref={loginButtonRef} onClick={() => navigate('/login', { state: { from: location.pathname } })}>
                    Login
                </ PrimaryButton>
            }

        </ButtonsWrapper>
    </>
}

function showComment(comment, currentUser, actionButtonHandlers, isDeleted) {

    let liked = false;

    if (currentUser) {
        const likedUsers = comment.likedUsers;
        liked = likedUsers.filter((likedUser) => likedUser.email === currentUser.email).length > 0;
    }

    return (
        <CommentDetails>
            <RowFlexContainer>
                <CommentAuthor>{isDeleted ? <DeletedText>[deleted]</DeletedText> : (comment.user.name ? comment.user.name : comment.user.email)}</CommentAuthor>
                <CommentDate>{formatDistanceToNow(new Date(comment.createdAt))} ago</CommentDate>
            </RowFlexContainer>
            <CommentBody>{isDeleted ? <DeletedText>[This comment has been deleted by either the author or an admin]</DeletedText> : comment.body}</CommentBody>
            <CommentActions>
                <RowFlexContainer>
                    <LikeButton $liked={liked} onClick={() => actionButtonHandlers.like(comment)} disabled={isDeleted}>
                        <i className="fa-solid fa-heart"></i>
                        <LikeCount>{!isDeleted && comment.likeCount !== 0 && comment.likeCount}</LikeCount>
                    </LikeButton>
                    <ReplyButton onClick={() => actionButtonHandlers.reply(comment)} disabled={isDeleted}>Reply</ReplyButton>
                    {currentUser && comment.user.email === currentUser.email && <DeleteButton onClick={() => actionButtonHandlers.delete(comment)} disabled={isDeleted}>
                        <i className="fa fa-sm fa-trash"></i>
                    </DeleteButton>}
                </RowFlexContainer>
            </CommentActions>
        </CommentDetails>
    );
}

const fetchComments = async (setComments, slug) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${appConfig.comment}/all`, {
            params: { pageId: `${slug}` },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setComments(response.data);
    } catch (error) {
        showToastNotification("Sorry, we couldn't get the comments for this page. Try reloading the page!", 'error', 'comments');
        sendErrorLog({
            errorContent: error,
            pageId: slug,
            remarks: "Error while fetching comments",
        });
        console.error('Error fetching comments:', error);
    }
}

const postCommentThenFetch = async (comment, slug, user, setComments, parentCommentId) => {
    try {
        const token = localStorage.getItem('token');
        await axios.post(`${appConfig.comment}`, {
            pageId: slug,
            body: comment,
            authorEmail: user?.email,
            authorPhotoUrl: user?.photoURL,
            parentCommentId: parentCommentId,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(() => fetchComments(setComments, slug, token));
    } catch (error) {
        console.error('Error posting comment:', error);
        showToastNotification("Sorry, we couldn't catch your comment, please try again.", 'error', 'comments');
        sendErrorLog({
            errorContent: error,
            pageId: slug,
            user: comment.email,
            remarks: "Error while posting comment",
        });
    }
}

const deleteCommentThenFetch = async (comment, slug, setComments) => {
    try {
        const token = localStorage.getItem('token');
        await axios.post(`${appConfig.commentDelete}`, {
            id: comment.id
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(() => fetchComments(setComments, slug));
    } catch (error) {
        console.error('Error deleting comment:', error);
        showToastNotification("Sorry, we couldn't delete your comment, please try again.", 'error', 'comments');
        sendErrorLog({
            errorContent: error,
            pageId: slug,
            user: comment.email,
            remarks: "Error while deleting comment",
        });
    }
}

const DeletedText = styled.p`
    font-style: italic;
    opacity: 0.9;
    font-weight: 100;
`

const Root = styled.div`
    margin: 5px 0;
`