import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../Footer";
import NavBar from "../../navbar/NavBar";
import CommonTopBar from "../../top-bar/CommonTopBar";

const DefaultLayout = () => {
  return (
    <>
      <CommonTopBar/>
      <NavBar />
      <Outlet />
      <div id="footer">
        <Footer />
      </div>
    </>
  );
};

export default DefaultLayout;