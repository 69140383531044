import styled from "styled-components";
import AmazonAd from "../../assets/BannerAds/Amazon_Todays_Deals.jpg";
import ZerodhaLogo from "../../assets/BannerAds/ZerodhaLogo.png";
// import IDFCLogo from "../../assets/BannerAds/IDFC_Logo.png";
import IDFCLogo from "../../assets/BannerAds/IDFC_Logo.png";
import { AMAZON_TODAYS_DEALS_AD, CC_ADS, ZERODHA_REFFERAL_LINK } from "../../utils/Constants";

export const Banner = ({ imgSrc = AmazonAd, link = AMAZON_TODAYS_DEALS_AD }) => {
    return (
        <Root className="container">
            <Row className="row">
                <BannerImg className="col-12 col-xxl-8" onClick={() => window.open(link, '_blank')} alt="Banner" src={imgSrc} />
                <ColFlex className="col-12 col-xxl">
                    <AdButtonCard className="row" key="zerodha" color="blue" href={ZERODHA_REFFERAL_LINK} target="_blank">
                        <img className="row" src={ZerodhaLogo} alt="Zerodha" />
                        <Content className="row">
                            Start investing in stocks @0 Brokerage
                        </Content>
                    </AdButtonCard>
                    <AdButtonCard className="row" key="idfc" href={CC_ADS.filter(cc => cc.bank.includes("IDFC"))[0].link} color="pink" target="_blank" >
                        <img className="row" src={IDFCLogo} alt="IDFC" style={{
                            maxHeight: "60px"
                        }}/>
                        <Content className="row">
                            Get a Lifetime Free Credit Card with sign up bonus of Rs 500
                        </Content>
                    </AdButtonCard>
                </ColFlex>
            </Row>
            <Row>
                <p style={{
                    margin: "auto",
                    fontSize: "0.45rem",
                }}>
                    #We work with affiliate networks and get paid a commission in certain instances. All trademarks, logos and brand names are the property of their respective owners. T&C apply.
                </p>
            </Row>
        </Root>
    )
}

const Root = styled.div`
    margin: 0 auto;
    padding: 0;
    @media (max-width: 768px){
        padding: 0 10px;
    }
`;

const Row = styled.div`
    gap: 10px;
`

const ColFlex = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    @media (max-width: 1300px) {
        flex-direction: row;
        gap: 10px;
    }
`;

const getRandomColor = (color) => {
    const colorArray = ['var(--pastel-blue)', 'var(--pastel-pink)']
    if (color) {
        if (color === 'blue') return colorArray[0]
        if (color === 'pink') return colorArray[1]
    }
    return colorArray[Math.floor(Math.random() * colorArray.length)]
}

const AdButtonCard = styled.a`
    background-color: ${(props) => getRandomColor(props.color)};
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0;
    gap: 15px;
    text-decoration: none;
    
    img{
        max-width: 150px; 
        margin: 0;
        max-height: 20px;
        width: auto;
    }
    
    &:hover {
        color: black;
    }

    @media (max-width: 1300px) {
        text-align: center;
        width: 50%;
        justify-content: space-evenly;
    }
`

const BannerImg = styled.img`
    cursor: pointer;
    overflow: hidden;
`
const Content = styled.p`
    text-align: center;
    justify-content: center;
    font-size: 1.11rem;
`