import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { page_titles } from "../PageTitles";
import DividendCalendar from "./DividendCalendar";
import React from "react";
import { getAppConfig } from "../../config/appConfig";

const appConfig = getAppConfig();

function DataFetchingDivCal() {
  const [stock_data, setStockData] = useState([]);

  const makeMyNewData = (stock_data) => {

    const mappedData = stock_data?.map((item) => {
      const newObject = {
        short_name: item[0],
        long_name: item[1],
        div_or_share: item[2],
        Ex_date: item[3],
        div_amount: Number(item[4]),
        price: !item[5] ? "-" : Number(item[5]).toFixed(2),
        div_yield: !item[6] ? "-" : Number(item[6]).toFixed(2)
      }
      return newObject;
    });
    // console.log("mappedData", mappedData);
    return mappedData
  }

  const getStockDetails = async () => {
    const APIKey = appConfig.dividendFetchConfig.apiKey;
    const sheetID = appConfig.dividendFetchConfig.sheetID;
    const range =  appConfig.dividendFetchConfig.range;

    const request = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values/${range}?key=${APIKey}`;

    const stock_response = await fetch(request)
      .then(response => response.json())
      .then(data => data.values)
      .catch(error => {
        console.error(error);
      });

    const mappedItems = makeMyNewData(stock_response);

    setStockData(mappedItems);
  }

  useEffect(() => {
    getStockDetails();
  }, []);


  return (
    <>
      <Helmet>
        <title>{page_titles["upcoming-dividend-calendar"]}</title>
      </Helmet>
      <DividendCalendar stock_data={stock_data} />
    </>
  );
}

export default DataFetchingDivCal;
