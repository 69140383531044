import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../App";
import { styled } from 'styled-components';
import { PrimaryButton } from "../common-components/common-styled-components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import useUserAuthentication from "../user-authetication/useUserAuthentication";
import LogoDark from "../../assets/logo-dark.png";
import "./navBar.css";
import UserAvatar from "../common-components/common-styled-components/UserAvatar";
import { openAffiliateLinkIfValid } from "../../utils/openAffiliateLink";

function NavBar() {
    const { user, isUserLoggedIn } = useContext(AppContext);
    const { userSignOut } = useUserAuthentication();
    const navigate = useNavigate();
    const [isUserDetailsVisible, setIsUserDetailsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
    const userDetailsRef = useRef(null);
    const location = useLocation();

    const handleClickOutside = (event) => {
        if (userDetailsRef.current && !userDetailsRef.current.contains(event.target)) {
            setIsUserDetailsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 576);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleOptionClick = (callback) => {
        setIsUserDetailsVisible(false);
        callback();
    };

    return (
        <header className="App-header">
            <nav className="navbar navbar-dark navbar-expand-sm">
                <Link className="navbar-brand margin-left-5vmin" to="/">
                    <img width={100} src={LogoDark} alt="pFinTools Logo" />
                </Link>

                <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="false">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbar">
                    <ul className="navbar-nav ms-auto" id="navbarSupportedContent">
                        <li className="nav-item mx-auto">
                            <Link to='/upcoming-dividend-calendar' className="nav-link btn-nav m-1">
                                Dividend Calendar
                            </Link>
                        </li>

                        <li className="nav-item mx-auto">
                            <Link to="/no-cost-emi-calculator" onClick={() => openAffiliateLinkIfValid({}, "/no-cost-emi-calculator")} className="nav-link btn-nav m-1">
                                Real Cost of NCE
                            </Link>
                        </li>
                        <li className="nav-item mx-auto">
                            <Link to="/faq" className="nav-link btn-nav m-1">
                                FAQ
                            </Link>
                        </li>

                        {isMobile ? (
                            <>
                                {isUserLoggedIn ? (
                                    <>
                                        <li className="nav-item mx-auto">
                                            <AvatarDropdownButton onClick={() => handleOptionClick(() => navigate('/my-profile'))}>
                                                My Profile
                                            </AvatarDropdownButton>
                                        </li>
                                        <li className="nav-item mx-auto">
                                            <AvatarDropdownButton onClick={() => handleOptionClick(userSignOut)}>
                                                Log out
                                            </AvatarDropdownButton>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <AvatarDropdownButton onClick={() => handleOptionClick(() => navigate('/login', { state: { from: location.pathname } }))}>
                                            Login
                                        </AvatarDropdownButton>
                                        <AvatarDropdownButton onClick={() => handleOptionClick(() => navigate('/signup'))}>
                                            Signup
                                        </AvatarDropdownButton>
                                    </>
                                )}
                            </>
                        ) : (
                            <li className="nav-item user ms-2 d-none d-sm-flex" ref={userDetailsRef}>
                                <div onMouseEnter={() => setIsUserDetailsVisible(true)}>
                                    <UserAvatar photoUrl={user?.photoURL} />
                                </div>
                                <div className={`user-details ${isUserDetailsVisible ? 'visible' : ''}`}>
                                    {isUserLoggedIn ? (
                                        <div className="m-2">
                                            <p className="text-center"><strong>Welcome,</strong> <br />{user?.displayName}</p>
                                            <AvatarDropdownButton onClick={() => handleOptionClick(() => navigate('/my-profile'))}>
                                                My Profile
                                            </AvatarDropdownButton>
                                            <AvatarDropdownButton onClick={() => handleOptionClick(userSignOut)}>
                                                Log out
                                            </AvatarDropdownButton>
                                        </div>
                                    ) : (
                                        <div className="m-2">
                                            <AvatarDropdownButton onClick={() => handleOptionClick(() => navigate('/login', { state: { from: location.pathname } }))}>
                                                Login
                                            </AvatarDropdownButton>
                                            <AvatarDropdownButton onClick={() => handleOptionClick(() => navigate('/signup'))}>
                                                Signup
                                            </AvatarDropdownButton>
                                        </div>
                                    )}
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default NavBar;

const AvatarDropdownButtonStyled = styled.a`
    background-color: black;
    color: white;
    max-height: 50px;

    a {
        color: white;
        text-decoration: none;
        margin: 0 auto;
    }

    &:hover {
        background-color: black;
        color: white;
    }
`;

const AvatarDropdownButton = ({ children, ...props }) => {
    return (
        <AvatarDropdownButtonStyled className="nav-link btn-nav m-1" {...props}>
            {children}
        </AvatarDropdownButtonStyled>
    );
};