import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import Tabs from "./Tabs";
import { Banner } from "../banners/BannerWithButtons";
import Comments from "../comments/Comments";

function DividendCalendar(props) {
  var [table_order, setOrder] = useState("ASC");
  var [div_cal_data, setDivCalData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [searchCompanyTerm, setsearchCompanyTerm] = useState("");
  const [searchSymbolTerm, setsearchSymbolTerm] = useState("");
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [minDivAmt, setMinDivAmt] = useState(0);
  const [maxDivAmt, setMaxDivAmt] = useState(0);
  const [minDivYield, setMinDivYield] = useState(0);
  const [maxDivYield, setMaxDivYield] = useState(0);
  const [consolidateShortNames, setConsolidatedShortNames] = useState([]);
  const [minFilterDate, setMinFilterDate] = useState('');
  const [minFilterDateStoredFlag, setMinFilterDateStoredFlag] = useState(true);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  var fromDateString = formatDate(fromDate);
  var endDateString = formatDate(toDate);

  useEffect(() => {

    if (fromDateString != `` && minFilterDateStoredFlag) { //At first fromDateString stores this string with NaN, then it goes on to store the correct value
      setMinFilterDate(fromDateString);
      setMinFilterDateStoredFlag(false)
    }

  }, [fromDate])

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
    if (!isNaN(dateObject)) {
      const day = dateObject.getDate().toString().padStart(2, '0');
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const year = dateObject.getFullYear();
      return `${year}-${month}-${day}`;
    }
    return '';

  }


  useEffect(() => {
    window.scrollTo(0, 0);

    setSpinner(true);
    const setDivVar = async () => {
      if (!(props.stock_data == undefined || props.stock_data.length < 1)) {
        setDivCalData(props.stock_data);
        setFilteredData(props.stock_data);

        const newFromDate = props.stock_data[0].Ex_date;
        setFromDate(newFromDate);

        const newToDate = props.stock_data[props.stock_data.length - 1].Ex_date;
        setToDate(newToDate);

        const newMaxPrice = props.stock_data.reduce((max, item) => {
          const price = parseFloat(item.price);
          return price > max ? price : max;
        }, 0);
        setMaxPrice(newMaxPrice);

        const newMaxDivAmt = props.stock_data.reduce((max, item) => {

          if (item.div_amount !== "TBA") {
            const divAmt = parseFloat(item.div_amount);
            return divAmt > max ? divAmt : max;
          }
          else {
            return max;
          }

        }, 0);
        setMaxDivAmt(newMaxDivAmt);

        const newMaxDivYield = props.stock_data.reduce((max, item) => {

          if (item.div_yield !== "TBA") {
            const divYield = parseFloat(item.div_yield);
            return divYield > max ? divYield : max;
          }
          else {
            return max;
          }

        }, 0);
        setMaxDivYield(newMaxDivYield);


        await delay(2000)
        setSpinner(false)
      }
      else {
        setSpinner(false);
      }

    }
    setDivVar();

  }, [props.stock_data]);


  useEffect(() => {

    const shortNames = filteredData.map((stock) => stock.short_name);
    const consolidateShortNamesWithStars = shortNames.filter((element) => element.endsWith("*"));
    setConsolidatedShortNames(consolidateShortNamesWithStars.map((shortName) => shortName.replace(/\*/g, '')));

  }, [filteredData])


  var index = 0;
  const DisplayData = filteredData.map((item) => {
    index++;
    return (
      <tr className="text-light table-cell" key={index + `${item.short_name}`}>
        <td className="my-5">{item.short_name}</td>
        <td>{item.long_name}</td>
        {/* <td>{item.div_or_share}</td> */}
        <td style={{ whiteSpace: "nowrap" }}>{item.Ex_date.replace(/^0+/, '')}</td>
        <td>{isNaN(item.div_amount) ? 'TBA' : parseFloat(item.div_amount).toFixed(2)}</td>
        <td>{item.price}</td>
        <td>{item.div_yield}</td>
      </tr>
    );
  });

  const sorting = (col) => {
    if (table_order === "ASC") {
      const sorted = filteredData.sort((a, b) =>
        a[col].toLowerCase().localeCompare(b[col].toLowerCase())
      );
      setFilteredData(sorted);
      setOrder("DESC");
      // setSortedFlag("true");
    } else {
      const sorted = filteredData.sort((a, b) =>
        b[col].toLowerCase().localeCompare(a[col].toLowerCase())
      );
      setFilteredData(sorted);
      setOrder("ASC");
      // setSortedFlag("true");
    }
  };

  const sortingExDate = () => {
    if (table_order === "ASC") {
      const sorted = filteredData.sort((a, b) => {
        let date1 = new Date(a["Ex_date"]);
        let date2 = new Date(b["Ex_date"]);
        return date1 - date2;
      }
      );
      setFilteredData(sorted);
      setOrder("DESC");
      // setSortedFlag("true");
    } else {
      const sorted = filteredData.sort((a, b) => {
        let date1 = new Date(a["Ex_date"]);
        let date2 = new Date(b["Ex_date"]);
        return date2 - date1
      }
      );
      setFilteredData(sorted);
      setOrder("ASC");
    }
  }

  const sortingPrice = () => {

    if (table_order === "ASC") {
      const sorted = filteredData.sort((a, b) => {
        const num1 = a["price"] === "-" ? -Infinity : Number(a["price"]);
        const num2 = b["price"] === "-" ? -Infinity : Number(b["price"]);
        return num1 - num2;
      }
      );
      setFilteredData(sorted);
      setOrder("DESC");
      // setSortedFlag("true");
    } else {
      const sorted = filteredData.sort((a, b) => {
        const num1 = a["price"] === "-" ? -Infinity : Number(a["price"]);
        const num2 = b["price"] === "-" ? -Infinity : Number(b["price"]);
        return num2 - num1;
      }
      );
      setFilteredData(sorted);
      setOrder("ASC");
    }

  }

  const sortingdiv_yield = () => {
    if (table_order === "ASC") {
      const sorted = filteredData.sort((a, b) => {
        const num1 = a["div_yield"] === "-" ? -Infinity : Number(a["div_yield"]);
        const num2 = b["div_yield"] === "-" ? -Infinity : Number(b["div_yield"]);
        return num1 - num2;
      }
      );
      setDivCalData(sorted);
      setOrder("DESC");
      // setSortedFlag("true");
    } else {
      const sorted = filteredData.sort((a, b) => {
        const num1 = a["div_yield"] === "-" ? -Infinity : Number(a["div_yield"]);
        const num2 = b["div_yield"] === "-" ? -Infinity : Number(b["div_yield"]);
        return num2 - num1;
      }
      );
      setDivCalData(sorted);
      setOrder("ASC");
    }
  }

  const sortingdiv_amt = () => {
    if (table_order === "ASC") {
      const sorted = filteredData.sort((a, b) => {
        const num1 = isNaN(a["div_amount"]) ? -Infinity : Number(a["div_amount"]);
        const num2 = isNaN(b["div_amount"]) ? -Infinity : Number(b["div_amount"]);
        return num1 - num2;
      }
      );
      setDivCalData(sorted);
      setOrder("DESC");
      // setSortedFlag("true");
    } else {
      const sorted = filteredData.sort((a, b) => {
        const num1 = isNaN(a["div_amount"]) ? -Infinity : Number(a["div_amount"]);
        const num2 = isNaN(b["div_amount"]) ? -Infinity : Number(b["div_amount"]);
        return num2 - num1;
      }
      );
      setDivCalData(sorted);
      setOrder("ASC");
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const filtered = div_cal_data.filter((item) => {

      // Matches Ex Date
      const itemDate = new Date(item.Ex_date).getTime();
      const fromDateTime = fromDate && new Date(fromDate).getTime();
      const toDateTime = toDate && new Date(toDate).getTime();
      const matchesExDate = (!fromDateTime || itemDate >= fromDateTime) && (!toDateTime || itemDate <= toDateTime);

      // Matches Company
      const matchesCompanyName = item.long_name.toString().toLowerCase().includes(searchCompanyTerm.toLowerCase());

      // Matches Symbol
      const matchesSymbol = item.short_name.toString().toLowerCase().includes(searchSymbolTerm.toLowerCase());

      // Matches Prices
      const matchesPrices = item.price >= parseFloat(minPrice) && item.price <= parseFloat(maxPrice);

      // Mtaches Dividend Amount
      const matchesDivAmt = item.div_amount >= parseFloat(minDivAmt) && item.div_amount <= parseFloat(maxDivAmt);

      // Matches Div Yield
      const matchesDivYield = item.div_yield >= parseFloat(minDivYield) && item.div_yield <= parseFloat(maxDivYield);

      return matchesExDate && matchesCompanyName && matchesSymbol && matchesPrices && matchesDivAmt && matchesDivYield;
    });
    setFilteredData(filtered);
  }

  return (
    <>


      <div className="container py-3">

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link className="breadcrumbs-link" to="/"><i className="fa fa-home fa-xs"></i></Link></li>
            <li className="breadcrumb-item active" aria-current="page">Dividend Calendar</li>
          </ol>
        </nav>


        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10">
            <h1 className="display-5 my-3 text-dark-green">Dividend Calendar</h1>
            <p className="text-left">
              <br />
              Welcome to India's first and only practical <strong>Dividend Calendar!</strong> Here you can find all upcoming dividends announced with <em>dividend yield</em> as a function of last traded price
              (instead of the face value) of the stock.
            </p>

            <div className="tabs">
              <Tabs tab="upcoming" />
            </div>

            <div className="filters">

              <div className="p-3 pb-1">
                <form onSubmit={handleSubmit}>
                  <div className="d-flex flex-wrap">

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">Symbol:</legend>
                      <input className="form-control-sm" type="text" name="Symbol-filter" onChange={(e) => setsearchSymbolTerm(e.target.value)} />
                    </fieldset>

                    <fieldset className="border-filter">
                      <legend className="float-none w-auto">Company Name:</legend>
                      <input type="text" className="form-control-sm" name="Company-filter" onChange={(e) => setsearchCompanyTerm(e.target.value)} />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">Ex Date From:</legend>
                      <input className="form-control-sm" placeholder="dd-mm-yyyy" type="date" min={minFilterDate} value={fromDateString} onFocus={(e) => e.target.type = 'date'} onChange={e => setFromDate(e.target.value)} />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">Ex Date To:</legend>
                      <input className="form-control-sm" placeholder="dd-mm-yyyy" type="date" min={minFilterDate} value={endDateString} onFocus={(e) => e.target.type = 'date'} onChange={e => setToDate(e.target.value)} />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">Min Dividend Amount:</legend>
                      <input className="form-control-sm" type="text" value={minDivAmt} onChange={(e) => setMinDivAmt(e.target.value)} />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">Max Dividend Amount:</legend>
                      <input className="form-control-sm" type="text" value={maxDivAmt} onChange={(e) => setMaxDivAmt(e.target.value)} />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">Min Price:</legend>
                      <input type="text" className="form-control-sm" value={minPrice} onChange={e => setMinPrice(e.target.value)} />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">Max Price:</legend>
                      <input type="text" className="form-control-sm" value={maxPrice} onChange={e => setMaxPrice(e.target.value)} />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">Min Dividend Yield (%):</legend>
                      <input className="form-control-sm" type="text" value={minDivYield} onChange={(e) => setMinDivYield(e.target.value)} />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">Max Dividend Yield (%):</legend>
                      <input className="form-control-sm" type="text" value={maxDivYield} onChange={(e) => setMaxDivYield(e.target.value)} />
                    </fieldset>

                  </div>

                  <p className="text-center">
                    <button className="btn btn-submit mx-auto mt-3" type="submit">Apply Filters</button>
                  </p>
                </form>
                <br />
              </div>
            </div>
          </div>

          <div className="col-md-1"></div>
        </div>


        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10">
            <div className="dividend-table w-100 mt-5">
              {spinner ? <Spinner /> :
                <>
                  <table className="table sortable">
                    <thead id="table-head" className="text-light table_head">
                      <tr>
                        <th key="symbol" >
                          Symbol <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sorting("short_name")}></i>
                        </th>
                        <th key="company" >
                          Company <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sorting("long_name")}></i>
                        </th>
                        {/* <th key="purpose" width="">
                      Purpose <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sorting("div_or_share")}></i>
                    </th> */}
                        <th key="ex_date"  >
                          Ex-Date <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sortingExDate()}></i>
                        </th>
                        <th key="div_amt" >
                          Dividend (&#x20b9;) <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sortingdiv_amt()}></i>
                        </th>
                        <th key="price">
                          Stock Price (&#x20b9;) <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sortingPrice()}></i>
                        </th>
                        <th key="div_yield">
                          Yield (%) <i className="fa-solid fa-sort fa-xs sort_icon" onClick={() => sortingdiv_yield()}></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody id="table-body">
                      {DisplayData}
                    </tbody>
                  </table>
                </>}
            </div>
            {consolidateShortNames.length > 0 ?
              <div className="row">
                <p></p>
                <small className="px-0"><b>*</b>For the sake of practicality, we have consolidated multiple
                  dividends declared for {consolidateShortNames.map((shortName, index) => {
                    if (index == consolidateShortNames.length - 1)
                      return <>{shortName} </>
                    else
                      return <>{shortName}, </>
                  })} with the same ex-date. To see individual
                  dividends declared, go to the <a href="/his-div-dat" className="link-page">Historic Dividends</a> section and
                  apply the required date filters (yes it works for future dates also).
                </small>
              </div>
              :
              <></>
            }
          </div>
          <div className="col-md-1"></div>
        </div>

        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10">

            <div className="row">
              <p className="font-weight-light mt-5">To be eligible to get the dividend you should have bought the shares atleast one business day before the ex date. You may sell the stock on ex-date or after to get the dividend.</p>
            </div>

            <div className="row my-4">
              <h5>Related Links:</h5>
              <ul className="list-group">
                <li className="list-group-item bg-transparent">
                  <a className="link-page" href="https://pFinTools.com/faq">
                    Frequently Asked Questions
                  </a>
                </li>
                <li className="list-group-item bg-transparent">
                  <a className="link-page" href="http://pFinTools.com/faq#dividend-calendar-how-does-a-dividend-affect-the-stock-price">
                    How Dividends Affect Stock Prices?
                  </a>
                </li>
                <li className="list-group-item bg-transparent">
                  <a className="link-page" href="http://pFinTools.com/faq#dividend-calendar-what-is-the-ex-date-and-what-is-the-record-date-how-are-the-two-related">
                    What is ex-date?
                  </a>
                </li>
                <li className="list-group-item bg-transparent">
                  <a className="link-page" href="http://pFinTools.com/faq#dividend-calendar-what-is-dividend-stripping-and-what-are-its-tax-implications-in-india">
                    Tax implications of dividend stripping in India
                  </a>
                </li>
              </ul>
            </div>

            <div className="row">
              <small className="mb-5 desclaimer">
                <strong>Disclaimer:</strong><i><br />The data presented here is for informational purpose only and does not solicit investment advise. We advise you to check with certified experts before making any investment decision.</i> <br />
              </small>
            </div>

            <div>
              <Comments articleId={'upcoming_dividend_calender'} />
            </div>

            {/* Amazon Banner */}
            <div className="my-4">
              <Banner />
            </div>

          </div>
          <div className="col-1"></div>
        </div>

      </div>

    </>
  );
}

export default DividendCalendar;

