import { useEffect, useState } from "react";
import magicBall from "../../assets/magic-ball.png";
import historyIcon from "../../assets/history.png";
import { Link } from "react-router-dom";

function Tabs(props) {
    const [tab, setTab] = useState("");

    useEffect(() => {
        setTab(props.tab);
    }, [props.tab])

    return (
        <>
            <div className="mt-4 filter-section-tabs mx-auto">
                {tab === "upcoming" ?
                    <ul className="nav nav-tabs nav-justified font-size-small" role="tablist">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to={'#'}>
                                <img src={magicBall} height="40" alt="Magic Ball icon" />
                                <p className="font-size-small w-75 mx-auto">Upcoming Dividends</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={'/historic-dividend-calendar'}>
                                <img src={historyIcon} height="40" alt="History" />
                                <p className="font-size-small w-75 mx-auto">Historic Dividends</p>
                            </Link>
                        </li>
                    </ul>
                    :
                    <ul className="nav nav-tabs nav-justified font-size-small" role="tablist">
                        <li className="nav-item">
                            <Link className="nav-link" to={'/upcoming-dividend-calendar'}>
                                <img src={magicBall} height="40" alt="Magic Ball icon" />
                                <p className="font-size-small">Upcoming Dividends</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to={'#'}>
                                <img src={historyIcon} height="40" alt="History" />
                                <p className="font-size-small w-75 mx-auto">Historic Dividends</p>
                            </Link>
                        </li>
                    </ul>
                }
            </div>

        </>
    );
}

export default Tabs;